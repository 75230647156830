<template>
  <div class="d-flex justify-center mb-6">
    <v-card elevation="0" style="width: 98%; max-width: 1000px;">
      <v-btn class="ma-2" outlined @click="goBack">
        <span style="font-size: 1.2rem" class="ml-2 mb-1">
          رجوع
        </span>
        <v-icon>mdi-backburger</v-icon>
      </v-btn>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="fields.name"
          :rules="nameRules"
          label="التسمية"
          required
        ></v-text-field>

        <v-text-field
          v-model="fields.code"
          :rules="codeRules"
          label="الرمز"
          required
        ></v-text-field>

        <v-btn class="mr-4" @click="goBack" depressed outlined large>
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            إلغاء
          </span>
          <v-icon>cancel</v-icon>
        </v-btn>

        <v-btn
          :disabled="!valid"
          class="mr-4"
          @click="submit"
          depressed
          outlined
          large
        >
          <span style="font-size: 1.2rem" class="ml-2 mt-1">
            حفظ
          </span>
          <v-icon>save</v-icon>
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    fields: { name: "", code: "" },
    valid: true,
    nameRules: [(v) => !!v || "Name is required"],
    codeRules: [(v) => !!v || "Code is required"],
  }),

  methods: {
    ...mapActions("languages", ["addLanguage"]),
    submit() {
      this.addLanguage(JSON.stringify(this.fields)).then(() => {
        this.$router.push({ name: "BackendLanguage" });
      });
    },
    goBack() {
      this.$router.push({ name: "BackendLanguage" });
    },
  },
};
</script>
